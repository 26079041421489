import React, { useEffect, useState } from "react";

export default function MainWindow() {
  const images = ["/landingImage1.png", "/InteriorImage11.jpeg", "/2For1PizzaHouse_Hero.jpg"];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(true); // State to control the popup visibility

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, [currentImageIndex, images.length]);

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(false); // Hide the popup after 5 seconds
    }, 3000);

    return () => clearTimeout(popupTimer); // Clean up on component unmount
  }, []);

  // Common button styles
  const buttonClass = "font-semibold px-6 py-3 rounded-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 text-center w-full max-w-md";

  return (
    <div className="relative lock min-h-[500px] min-w-full">
      {images.map((image, index) => (
        <div
          key={image}
          className="absolute inset-0 h-full w-full bg-center bg-cover transition-opacity duration-[2000ms]"
          style={{
            backgroundImage: `url(${image})`,
            opacity: index === currentImageIndex ? 1 : 0,
          }}
        />
      ))}
      <div className="relative h-screen z-10">
        <div className="max-w-screen-xl align-center justify-between mx-auto h-full z-0">
          <div className="md:flex h-full">
            <div className="w-1/2"></div> {/* Left half empty for spacing */}
            <div className="md:w-1/2 w-full flex flex-col justify-center align-middle items-center h-full">
              {/* Centering content */}
              <img src="/logo512.png" alt="2 For 1 Pizza House" className="" />
              <h1 className="text-4xl font-semibold text-center text-white">
                Welcome to 2 For 1 Pizza House
              </h1>
              <p className="text-lg text-center mt-4 text-white">
                Experience the perfect blend of spicy Indian flavors and classic Italian pizza at our unique pizzeria!
              </p>
              <div className="flex items-center justify-center mt-8 space-x-4">
                <a
                  href="https://www.clover.com/online-ordering/2-for-1-pizza-house-fresno"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <button className={`${buttonClass} bg-blue-700 text-white hover:bg-blue-600 focus:ring-blue-700`}>
                    Order Pickup
                  </button>
                </a>
                <a
                  href="https://slicelife.com/restaurants/ca/fresno/93720/2-for-1-pizza-house/menu?button_type=view_menu&format=banner&utm_term=banner&position=top&variant=light&breakpoint=desktop&button_text=view_menu&color=black&theme=minimal&utm_campaign=order_now_button&utm_content=widget&utm_medium=referral&utm_source=127.0.0.1&value_message=delivery_pickup&own_web_referrer=127.0.0.1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={`${buttonClass} bg-white text-black hover:bg-gray-100 focus:ring-gray-400`}>
                    Order Delivery
                  </button>
                </a>
                <a href="/menu">
                  <button className={`${buttonClass} bg-gray-900 text-white hover:bg-gray-800 focus:ring-gray-900`}>
                    View Menu
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Centered and Enhanced Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-10 rounded-lg shadow-2xl transform transition-all duration-500 ease-out scale-100">
            <h2 className="text-5xl font-bold text-center text-red-600 mb-4">We Deliver!</h2>
            <p className="text-xl text-center text-gray-700">
              Get your favorite pizza delivered hot and fresh to your doorstep!
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
