import React from 'react';

export default function MenuItem({ title, description, price, image }) {
  return (
    <div className="p-4 bg-white rounded-lg shadow-lg text-center border-2 border-blue-600 hover:shadow-xl transition duration-300 transform hover:scale-105">
      <h3 className="text-2xl font-bold mb-2 text-red-600">{title}</h3>
      <p className="mb-4 text-gray-700">{description}</p>
      {price && <p className="font-semibold text-lg text-blue-600">{price}</p>}
      {image && <img src={image} alt={title} className="mt-4 mx-auto" />}
    </div>
  );
}
