export default function IngredientsLanderWindow() {
  const meatToppings = [
    "Chicken Tandoori",
    "B.B.Q Chicken",
    "Butter Chicken",
    "White Chicken",
    "Siracha Chicken",
    "Pepperoni",
    "Salami",
    "Bacon",
    "Beef",
    "Ham",
    "Italian Sausage",
  ];

  const veggieToppings = [
    "Paneer (Cottage Cheese)",
    "Mushrooms",
    "Banana Peppers",
    "Onions",
    "Jalapeños",
    "Tomatoes",
    "Fresh Garlic",
    "Pineapple",
    "Green Peppers",
    "Black Olives",
    "Green Olives",
    "Spinach",
    "Corn",
    "Cilantro",
    "Ginger",
    "Green Chilli",
    "Soya Chaap",
  ];

  const sauceToppings = [
    "Tomato Sauce",
    "BBQ Sauce",
    "White Sauce",
    "Shahi Sauce",
    "Garlic Sauce",
    "Pesto Sauce",
    "Buffalo Sauce",
    "Sweet and Smokey Sauce",
    "Honey Hot Sauce"
  ];

  return (
    <div
      className="max-h-max bg-cover bg-no-repeat min-h-max"
      style={{ backgroundImage: "url('/frontpage1.jpg')" }}
    >
      <div className="max-w-screen-xl items-center justify-between flex flex-col h-full mx-auto px-4 py-4">
        <div className="rounded-lg p-6 shadow-xl bg-black bg-opacity-60 my-4 w-full justify-items-center">
          <h2 className="text-4xl font-bold text-white mb-4 text-center"> 
            Feeling Creative?
          </h2>
          <p className="text-white leading-relaxed text-lg text-center">
            We use only the freshest ingredients in our pizzas. 
            Customize your pizza with our wide range of toppings!
          </p>
        </div>
        {/* "We Serve Beer" Notice */}
        <div className="rounded-lg p-4 shadow-xl bg-yellow-500 bg-opacity-80 my-4 w-full flex items-center justify-center">
          <h3 className="text-3xl font-semibold text-black text-center">
            We Serve Beer!
          </h3>
        </div>
        <div className="max-w-screen-xl items-center justify-between mx-auto md:grid md:grid-cols-3 h-full">
          <div className="flex flex-col items-center justify-center h-full w-full px-4 my-3">
            <div className="rounded-lg bg-black bg-opacity-60 shadow-xl h-full w-full">
              <h2 className="text-4xl font-bold text-white mb-4 text-center pt-4 my-4">
                Meat Toppings
              </h2>
              <div className="p-4 grid grid-cols-2 gap-4">
                {meatToppings.map((item) => (
                  <p className="text-white"> ✓ {item} </p>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-full w-full px-4 my-3">
            <div className="rounded-lg bg-black bg-opacity-60 shadow-xl h-full w-full">
              <h2 className="text-4xl font-bold text-white mb-4 text-center pt-4 my-4">
                Veggie Toppings
              </h2>
              <div className="p-4 grid grid-cols-2 gap-4">
                {veggieToppings.map((item) => (
                  <p className="text-white"> ✓ {item} </p>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-full w-full px-4 my-3">
            <div className="rounded-lg bg-black bg-opacity-60 shadow-xl h-full w-full">
              <h2 className="text-4xl font-bold text-white mb-4 text-center pt-4 my-4">
                Sauces
              </h2>
              <div className="p-4 grid grid-cols-2 gap-4">
                {sauceToppings.map((item) => (
                  <p className="text-white"> ✓ {item} </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
