import React from "react";
import NavBar from "../components/NavBar";

export default function PromotionsPage() {
  return (
    <div>
      <NavBar />
      <div className="flex flex-col items-center">
        <img src="/promotions0618.png" alt="Promotions" className="max-w-[1000px] w-full mb-4" />
        <img src="/lunchspecial2.png" alt="Lunch Special" className="max-w-[1000px] w-full mb-4" />
        <img src="/supercombos2.png" alt="Super Combos" className="max-w-[1000px] w-full" />
      </div>
    </div>
  );
}


