import NavBar from "../components/NavBar";
import MainWindow from "../components/MainWindow";
import AboutUsWindow from "../components/AboutUsWindow";
import IngredientsLanderWindow from "../components/IngredientsLanderWindow";
import ContactUsWindow from "../components/ContactUsWindow";
import CopyrightBar from "../components/CopyrightBar";

export default function LandingPage() {
    return (
        <div class="w-full">
          <NavBar />
          <MainWindow />
          <AboutUsWindow />
          <IngredientsLanderWindow />
          <ContactUsWindow />
          <CopyrightBar />
        </div>
      );
}