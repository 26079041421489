import React from "react";

export default function ContactUsWindow() {
  return (
    <div
      id="contact-us"
      className="h-fit bg-cover bg-no-repeat py-4"
      style={{ backgroundImage: "url('/backgroundImage3.png')" }}
    >
      <div className="max-w-screen-xl items-center justify-between mx-auto">
        <div className="md:flex flex-row justify-around border-gray-200 dark:bg-gray-900/50 dark:border-gray-700 rounded-lg h-auto p-4">
          <div className="p-6 md:w-1/4 md:min-w-fit rounded-lg">
            <h1 className="text-4xl font-bold text-white text-center">
              Contact Us
            </h1>
            <div className="grid grid-cols-1 justify-self-center gap-4 h-full p-4">
              <div className="h-full">
                <h3 className="text-lg font-semibold text-white">Find Us</h3>
                <p className="text-white">2 For 1 Pizza House</p>
                <p className="text-white">7797 N First St</p>
                <p className="text-white">Fresno, CA 93720</p>
              </div>
              <div className="h-full">
                <h3 className="text-lg font-semibold text-white">Hours</h3>
                <h4 className="text-white">Monday - Sunday</h4>
                <p className="text-white">10:00 AM - 10:00 PM</p>
              </div>
              <div className="h-full">
                <h3 className="text-lg font-semibold text-white">Email Us</h3>
                <p className="text-white font-semibold">
                  <a href="mailto:Dhesipizzahouse@gmail.com" className="text-white">
                    Dhesipizzahouse@gmail.com
                  </a>
                </p>
              </div>
              <div className="h-full">
                <h3 className="text-lg font-semibold text-white">Call Us</h3>
                <p className="font-semibold text-white">
                  <a href="tel:5597753200" className="text-white">
                    (559) 775-3200
                  </a>
                </p>
              </div>
            </div>
          </div>
          <iframe
            title="2 For 1 Pizza House Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.616123686841!2d-119.78043031424731!3d36.851668693214386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809442bb14a2d327%3A0xf6c2631697d6902d!2s7797%20N%20First%20St%2C%20Fresno%2C%20CA%2093720!5e0!3m2!1sen!2sus!4v1713477078601!5m2!1sen!2sus"
            className="md:max-w-[600px] min-h-[450px] w-full"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
