import NavBar from "../components/NavBar";
import MenuItem from "../components/MenuItem";
import CopyrightBar from "../components/CopyrightBar";
import React, { useState } from "react";

export default function MenuPage() {
  const [activeSection, setActiveSection] = useState("Vegetarian Pizzas");

  const pizzaSizes = [
    '10" Small $17.99',
    '12" Medium $21.99',
    '14" Large $25.99',
    '16" Extra Large $30.99',
  ];
  const twoForOneSpecialtySizes = [
    '10" Small $28.99',
    '12" Medium $39.90',
    '14" Large $45.90',
    '16" Extra Large $49.99',
  ];

  const sections = [
    {
      label: "Vegetarian Pizzas",
      items: [
        {
          title: "Cheese Pizza",
          description:
            "A classic cheese pizza topped with a rich tomato sauce and a generous layer of melted mozzarella cheese. Perfectly baked for a crispy crust and gooey cheese goodness.",
          price: "",
          image: "/cheese_pizza.jpg"
        },
        {
          title: "Vegetarian Special",
          description:
            "Mushrooms, green peppers, onions, black olives, pineapple and tomatoes.",
          price: "",
          image: "/vegetarian_special.jpg"
        },
        {
          title: "Tex-Mex Veggie",
          description:
            "Onions, green peppers, Jalapaños, cheddar cheese, taco seasoning and tomatoes.",
          price: "",
          image: "/2For1PizzaHouse_TexMex.jpg"
        },
        {
          title: "Paneer Special",
          description:
            "Onions, seasoned paneer, tomatoes, green peppers and mushrooms.",
          price: "",
          image: "/paneer_special.jpg"
        },
        {
          title: "Punjabi Veggie",
          description:
            "Mushrooms, green peppers, onions, cilantro, ginger, tomatoes and Jalapaños.",
          price: "",
          image: "/punjabi_veggie.jpg"
        },
        {
          title: "Palak Paneer",
          description:
            "Spinach, paneer, onions, green peppers and black olives.",
          price: "",
          image: "/2For1PizzaHouse_Palak_Paneer.jpg"
        },
        {
          title: "BBQ Paneer",
          description:
            "Onions, green peppers, BBQ paneer, pineapple, Jalapaños and BBQ sauce.",
          price: "",
          image: "/2For1PizzaHouse_BBQPaneer.jpg"
        },
        {
          title: "Greek Veggie Pizza",
          description:
            "Spinach, black olives, onions, feta cheese and tomatoes.",
          price: "",
          image: "/greek_veggie.jpg"
        },
        {
          title: "Shahi Paneer",
          description:
            "Onion, Green Peppers, Jalapaños, Cheddar Cheese, Taco Seasoning, Tomatoes.",
          price: "",
          image: "/shahi_paneer.jpg"
        },
        {
          title: "Paneer Tikka",
          description:
            "Onions, green peppers, paneer, pineapple, banana peppers and paneer tikka sauce.",
          price: "",
          image: "/paneer_tikka.jpg"
        },
        {
          title: "Garlic Chilli Paneer",
          description:
            "Onions, green peppers, paneer, black olives, Jalapaños and garlic sauce (med hot sauce).",
          price: "",
          image: "/garlic_chili_paneer.jpg"
        },
        {
          title: "Veggie Garden",
          description:
            "Onions, green peppers, green olives, banana peppers and tomatoes.",
          price: "",
          image: "/2For1PizzaHouse_VeggieGarden.jpg"
        },
        {
          title: "Super Veggie",
          description:
            "Spinach, roasted garlic, pineapple, green peppers, banana peppers and onions.",
          price: "",
          image: "/2FOr1PizzaHouse_SuperVeggie.jpg"
        },
        {
          title: "Soya Chaap Pizza",
          description:
            "Tandoori/shahi soya chaap, onions, green peppers, black olives and Jalapaños.",
          price: "",
          image: "/2For1PizzaHouse_SoyaChaap.jpg"
        },
        {
          title: "Pesto Veggie",
          description:
            "Onions, green peppers, black olives, Jalapaños, pesto sauce and cheddar cheese.",
          price: "",
          image: "/pesto_veggie.jpg"
        },
      ],
    },
    {
      label: "Meat Pizzas",
      items: [
        {
          title: "Pepperoni Pizza",
          description:
            "A timeless favorite featuring a delicious tomato sauce, mozzarella cheese, and plenty of spicy pepperoni slices.",
          price: "",
          image: "/pepper.jpg"
        },
        {
          title: "House Special",
          description:
            "Pepperoni, green peppers, ham, onions, mushrooms, tomatoes and bacon.",
          price: "",
          image: "/2For1PizzaHouse_HouseSpecial.jpg"
        },
        {
          title: "Meat Lover",
          description:
            "Pepperoni, salami, ham, bacon, ground beef and Italian sausage.",
          price: "",
          image: "/meat_lovers__pizza.jpg"
        },
        {
          title: "Fully Loaded",
          description:
            "Pepperoni, ham, Italian sausage, green peppers, onions and black olives.",
          price: "",
          image: "/2For1PizzaHouse_FullyLoaded.jpg"
        },
        {
          title: "Deluxe Pizza",
          description: "Ham, pineapple, pepperoni, ground beef and mushrooms.",
          price: "",
          image: "/Deluxe_pizza.jpg"
        },
        {
          title: "Greek Special",
          description:
            "Ground beef, feta cheese, black olives, onions and green peppers.",
          price: "",
          image: "/greek_special.jpg"
        },
        {
          title: "Smokey Hawaiian",
          description:
            "Ham, pineapple, bacon, green peppers, cheddar cheese and sweet and smokey sauce.",
          price: "",
          image: "/2For1PizzaHouse_SmokeyHawaiianPizza_2880x2304.jpg"
        },
        {
          title: "Hawaiian",
          description:
            "Ham, and pineapple",
          price: "",
          image: "/IMG_0657.jpg"
        },
        
        {
          title: "Beef Burger",
          description:
            "100% ground beef, onions, mushrooms, bacon, cheddar cheese and green peppers.",
          price: "",
          image: "/2For1PizzaHouse_BeefBurger.jpg"
        },
        {
          title: "Mexican Special",
          description:
            "Seasoned 100% ground beef, green peppers, onions, Jalapaños, hot sauce and cheddar cheese.",
          price: "",
          image: "/mexican_special.jpg"
        },
        {
          title: "Chef Special",
          description:
            "Pepperoni, pineapple, bacon, tomatoes and green peppers.",
          price: "",
          image: "/2For1PizzaHouse_ChefSpecial.jpg"
        },
        {
          title: "Dream Pizza House",
          description:
            "Pepperoni/ground beef, onions, green peppers, white/BBQ chicken, bacon and black olives.",
          price: "",
          image: "/dream_house.jpg"
        },
        {
          title: "Canadian Pizza",
          description: "Pepperoni, bacon, mushroom and double cheese.",
          price: "",
          image:"/2For1PizzaHouse_CanadianPizza_2880x2304.jpg"
        },
      ],
    },
    {
      label: "Chicken Pizzas",
      items: [
        {
          title: "Tandoori Chicken",
          description:
            "Tandoori/ white chicken, onions, mushrooms, green peppers and tomatoes.",
          price: "",
          image: "/2For1PizzaHouse_TandooriChicken.jpg"
        },
        {
          title: "Butter Chicken",
          description:
            "Butter chicken, onions, green peppers, tomatoes, banana peppers and butter sauce.",
          price: "",
          image: "/butter__chicken.jpg"
        },
        {
          title: "Garlic Chilli Chicken",
          description:
            "Chicken, onions, roasted garlic, mushrooms and green peppers (med hot sauce).",
          price: "",
          image: "/2For1PizzaHouse_GarlicChiliChicken.jpg"
        },
        {
          title: "Pesto Chicken",
          description:
            "Chicken, onions, green peppers, Jalapaños, cheddar cheese and pesto sauce.",
          price: "",
          image: "/2For1PizzaHouse_PestoChicken.jpg"
        },
        {
          title: "BBQ Chicken",
          description:
            "BBQ chicken, black olives, onions, green peppers and BBQ sauce.",
          price: "",
          image: "/2For1PizzaHouse_BBQChicken.jpg"
        },
        {
          title: "Buffalo Chicken",
          description:
            "Hot chicken, mushrooms, onions, green peppers, banana peppers and buffalo sauce.",
          price: "",
          image: "/buffalo_chicken.jpg"
        },
        {
          title: "Double Chicken",
          description:
            "Chilli chicken, onions, tandoori chicken, green peppers, banana peppers.",
          price: "",
          image: "/2For1PizzaHouse_DoubleChicken.jpg"
        },
        {
          title: "Chicken Tikka",
          description:
            "Chicken, onions, black olives, green peppers, Jalapaños and chicken tikka sauce.",
          price: "",
          image: "/2For1PizzaHouse_ChickenTikka.jpg"
        },
        {
          title: "Creamy Garlic Chicken",
          description:
            "Chicken, onions, black olives, green peppers, Jalapaños and garlic sauce (med hot sauce).",
          price: "",
          image: "/creamy_galic_chicken.jpg"
        },
        {
          title: "Sriracha Honey Chicken",
          description:
            "Onions, green peppers, chicken, pineapple, Jalapaños, cheddar cheese and honey hot sauce.",
          price: "",
          image: "/sriracha_honey_chicken.jpg"
        },
        {
          title: "Chicken Shawarma",
          description:
            "Chicken, sweet sauce, onions, tomatoes and green peppers.",
          price: "",
          image: "/chicken_shawarma.jpg"
        },
        {
          title: "Tropical Chicken",
          description:
            "Sweet and smokey sauce, white chicken or BBQ chicken, pineapple and mushrooms, cheddar cheese, green peppers.",
          price: "",
          image: "/2For1PizzaHouse_TropicalChicken.jpg"
        },
        {
          title: "Alfredo Chicken Pizza",
          description:
            "Chicken, onions, green peppers, black olives, banana peppers and Alfredo sauce.",
          price: "",
          image: "/alfredo_chicken.jpg"
        },
      ],
    },
    {
      label: "Super Combo Specials",
      items: [
        {
          title: " 1. Pizza & Pasta",
          description:
            "1 large (2 topping) + 1 lasagna(Beef or Veg) + 2 pcs Garlic bread with 1 free dipping sauce.",
          price: "$29.95",
        },
        {
          title: "2. Double Deal",
          description: "2 large double pepperoni or 2 large Hawaiian pizzas.",
          price: "$31.99",
        },
        {
          title: "3. Triple Deal",
          description:
            "Order any 2 large specialty pizzas + 1 large cheese pizza.",
          price: "$52.99",
        },
        {
          title: "4. Pizza & Wings Deal",
          description:
            "1 large (3 toppings) + 12 chicken wings with 1 free dipping sauce.",
          price: "$32.69",
        },
        {
          title: "5. Mega Dinner",
          description:
            "Order any 2 large specialty pizzas + 20 chicken wings with 2 free dipping sauces.",
          price: "$59.99",
        },
        {
          title: "6. Pizza Party",
          description: "Any 3 large specialty pizzas + 10” cheesy breadsticks.",
          price: '14" $62.85 or 16" $77.95',
        },
        {
          title: "7. Dinner For 2",
          description:
            "1 medium pizza (3 toppings) + 4 pcs Breaded Chicken strips + Fries",
          price: "$26.99",
        },
        {
          title: "8. Perfect Meal",
          description:
            "1 Large pizza (3 toppings) + 1 box Onion rings + 1 fountin drink + 1 free dipping sauce.",
          price: "$26.19",
        },
        {
          title: "9. Pizza Feast",
          description:
            'Order any 4 specialty pizzas + 12" cheesy breadsticks or 5 dipping sauce.',
          price: '14" $89.99 or 16" $99.99',
        },
        {
          title: "10. Family Get Together",
          description:
            "2 large pizzas (4 toppings) + 12 Chicken wings + one 2 liter soda.",
          price: "$50.99",
        },
      ],
    },
    {
      label: "Side Orders",
      items: [
        { title: "French Fries", description: "", price: "$6.99", image: "/french_fries.jpg" },
        { title: "Onion Rings", description: "", price: "$10.99", image: "/onion_rings.jpg" },
        { title: "Potato Wedge", description: "", price: "$9.99", image: "/potato_wedges.jpg" },
        { title: "Garlic Bread", description: "2 pieces", price: "$3.99", image: "/garlic_bread.jpg" },
        { title: "10” Cheesy Bread Sticks", description: "", price: "$11.99", image: "/cheesy_breadsticks.jpg" },
        { title: "Indian Style Cheesy Bread Sticks", description: "Onions, Bell Pepper, Jalapaños", price: "$14.99", image: "/Indian_cheesesticks.jpg" },
        {title: "Fully Loaded Fries", description: "Onions, Olives, Bell Peppers, Jalapaños and Cheese ", price: "$9.99", image: "/Loaded-fries.jpg"},
        {
          title: "9 Pieces Mozza Sticks & Marinara Sauce",
          description: "",
          price: "$11.99",
          image: "/mozza_sticks.jpg"
        },
        {
          title: "4 Pcs Breaded Chicken Breast Strips + Fries",
          description: "",
          price: "$11.99",
          image: "/chicken_strips.jpg"
        },
        {
          title: "Dipping Sauce",
          description: "Ranch | Spicy Ranch | BBQ",
          price: "$1.00",
          image: "/ranch.jpg"
        },
        {
          title: "Tasty Chicken Wings",
          description:
            "Seasoned, Breaded and Deep Fried to Perfection. Flavours to choose from: Buffalo, Honey Garlic, BBQ, Salt and Pepper, Golden Crispy Fried, Med Hot, Honey Hot, Tandoori Med/Hot, Teriyaki, BBQ Hot, BBQ Honey, Lemon Pepper, Butter Chicken, Punjabi Style.",
          price: "6 Pcs $9.99| 12 Pcs $14.49 | 24 Pcs $24.99",
          image: "/chicken_wings.jpg"
        },
        {
          title: "Vegetarian Cauliflower Wings",
          description:
            "Seasoned, Breaded and Deep Fried to Perfection. Flavours to choose from: Buffalo, Honey Garlic, BBQ, Salt and Pepper, Golden Crispy Fried, Med Hot, Honey Hot, Tandoori Med/Hot, Teriyaki, BBQ Hot, BBQ Honey, Lemon Pepper, Butter Chicken. Punjabi Style",
          price: "6 Pcs $9.99| 12 Pcs $14.49 | 24 Pcs $24.99",
          image: "/Cauliflower-Wings.jpg"
        },
        {
          title: "Baked Lasagnas",
          description:
            "All Orders Come with Garlic Bread. Vegetarian, Paneer, Beef, Chicken, Two Cheese (Tex-Mex). Add meatballs for $0.99 each.",
          price: "single $12.99 | 2 for 1 $21.99",
          image: "/baked_lasagnas.jpg"
        },
      ],
    },
    {
      label: "Specials",
      items: [
        {
          title: '7 Days Manager\'s Special',
          description:
            "All Day! Everyday! Order any 2 Large Specialty Pizzas for the price of 2 Medium Pizzas ",
          price: "$39.89",
        },
        { title: "Family Pleaser", description: "Order any 2 Large Specialty Pizza's(@ Reg Price) & Get 10\" Small Cheese Pizza Free ", price: "$45.89" },
        { title:"Walk-in/ Pick-up Special", description: "1 Large Cheese, Pepperooni or Hawaiian Pizza ", price: "$15.99" },
        { title: "Staff Special", description: "Order any 1 Large Specialty Pizza for the price of 1 Medium Pizza + Half order of Chicken Wings ", price: "$28.89" },
        { title: "Lunch Special", description: "Available from 10am - 2pm, Small pizza (cheese or pepperoni) + Fountain drink", price: "$9.99" },
      ],
    },
    {
      label: "Desserts",
      items: [
        { title: "Indian Style Kulfi", description: "", price: "1 Pc $2.99", image: "/kulfi.jpg" },
      ],
    },
    {
      label: "Beverages",
      items: [
        { title: "Fountain Drinks", description: "", price: "$1.99", image: "/fountain_drink.jpg" },
        { title: "Bottled Water", description: "", price: "$2.99", image: "/bw.jpg" },
        { title: "2L soda", description: "", price: "$2.99", image: "/2l.jpg" }, 
        {
          title: "Beer",
          description: "",
          price: "Pint $6.99 | 1/2 Pitcher $13.99 | Full Pitcher $18.99",
          image: "/beer.jpg"
        },
      ],
    },
  ];

  const toggleSection = (label) => {
    setActiveSection(activeSection === label ? null : label);
  };


  const renderPizzaSizes = () => (
    <ul className="text-center mt-2 mb-4 text-lg font-semibold text-gray-700">
      {pizzaSizes.map((size, index) => (
        <li key={index} className="mb-1">
          {size} &nbsp;|&nbsp; {twoForOneSpecialtySizes[index]} (2 for 1)
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      <NavBar />
      <div className="min-h-screen">
        <h1
          className="text-4xl font-bold text-center mb-4"
          style={{ fontFamily: "Dealers, sans-serif" }}
        >
          Menu
        </h1>
        <div className="w-full">
          <div className="max-w-screen-xl mx-auto px-4 py-4">
            <div className="flex flex-wrap justify-center space-x-4 mb-6">
              {sections.map((section, index) => (
                <h2
                  key={index}
                  className={`text-lg font-bold cursor-pointer ${activeSection === section.label ? "text-indigo-600" : "text-gray-800"}`}
                  onClick={() => toggleSection(section.label)}
                  style={{ fontFamily: "Dealers, sans-serif" }}
                >
                  {section.label}
                </h2>
              ))}
            </div>
            {sections.map(
              (section, sectionIndex) =>
                activeSection === section.label && (
                  <div key={sectionIndex}>
                    <h2
                      className="text-2xl font-bold text-center mb-4"
                      style={{ fontFamily: "Dealers, sans-serif" }}
                    >
                      {section.label}
                    </h2>
                    {(section.label === "Vegetarian Pizzas" ||
                      section.label === "Meat Pizzas" ||
                      section.label === "Chicken Pizzas") &&
                      renderPizzaSizes()}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {section.items.map((item) => (
                        <MenuItem
                          key={item.title}
                          title={item.title}
                          description={item.description}
                          price={item.price}
                          image={item.image}
                        />
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
      <CopyrightBar />
    </div>
  );
}
